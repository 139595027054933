<template>
  <main class="layout-main">
    <div class="inner">
      <div class="container-wrapper">
        <div class="layout-title">
          <h1 class="heading-page"><img src="@/assets/image/title/company.svg" alt="Company"><span class="sub">企業概要</span></h1>
        </div>
      </div>

      <section>
        <div class="copy-fukidashi -chigasaki"><p class="comment"><img src="@/assets/image/company/chigasaki.svg" alt="From Chigasaki"></p></div>
        <div class="container-barimage">
          <img src="@/assets/image/logo.svg" alt="One Better" class="logo">
        </div>
        <div class="container-wrapper">
          <div class="container-body" v-if="item.data.content" v-html="item.data.content.rendered"></div>
        </div>
      </section>
    </div>

  </main>
</template>


<script>
import { onMounted, onUpdated, onUnmounted, reactive, ref} from 'vue'
import axios from "axios"
import moment from 'moment'
const API_HOST = process.env.VUE_APP_APIRESTHOST;
//http://onebetter.cotori.co/c/wp-json/wp/v2/pages/2

export default {

  setup() {
    const pageId = ref('2')
    const item = reactive({
      data:[],
    })
    const  getPage = async () => {
      await axios.get('//' + API_HOST + '/c/wp-json/wp/v2/pages/' + pageId.value)
      .then( response => {
        item.data = response.data
        //console.log(item.data)

        setTimeout(() => {
          //isLoading.value = false;
        },100)
      }).catch(
        error => console.log(error)
      )
    }
    const setDate = ((date) => {
      return moment(date).format('YYYY.MM.DD')
    })

    onMounted(() => {
      getPage()
      //console.log('onMounted!')
    })
    onUpdated(() => {
      //console.log('updated!')
    })
    onUnmounted(() => {
      //console.log('unmounted!')
    })
    return{
      item,
      setDate
    }
  },
}

</script>




